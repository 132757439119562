import React, { useState } from "react";
import {
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import "../css/Products.css";
import emptyImage from "../images/emptyImage.jpg";
import ProductModal from "../components/ProductModal";

/**
 * Products page to display list of products
 * @param {function} handleAddToCart - Function to add product to cart
 * @param {function} handleIncreaseQuantity - Function to increase product quantity
 * @param {function} handleDecreaseQuantity - Function to decrease product quantity
 */
function Products({
  handleIncreaseQuantity,
  handleDecreaseQuantity,
  addToCart,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  // TODO: Replace products with actual data
  // Example products
  const products = [
    {
      id: 1,
      title: "Controller",
      price: 90.00,
      image: emptyImage,
      description: "A central HUB for data to be analysed and uploaded to datafarm’s internal servers",
    },
    {
      id: 2,
      title: "Sensor",
      price: 29.99,
      image: emptyImage,
      description: "A device capable of measuring a variety of compounds and sending to the controller",
    },
    {
      id: 3,
      title: "IOS/Android Client Application",
      price: 0,
      image: emptyImage,
      description: "An intuitive client application to interface with data measured from sensor devices.",
    },
  ];

  /**
   * Open modal with product details
   * @param {object} product
   */
  const openModal = (product) => {
    setSelectedProduct(product);
    setModalOpen(true);
  };

  // Close modal and reset selected product to null
  const closeModal = () => {
    setModalOpen(false);
    setSelectedProduct(null);
  };

  return (
    <div className="products-body">
      <h1
        style={{ fontWeight: "bolder", color: "black", marginBottom: "2rem" }}
      >
        Our Products
      </h1>
      {/* <MDBRow className="g-4"> */}
      <div className="products-card-container"> {/* Delete this to make it responsive */}
        {products.map((product) => (
          // <MDBCol
          //   xs={12}
          //   sm={6}
          //   md={4}
          //   lg={3}
          //   className="px-2"
          //   key={product.id}
          // >
          <MDBCard className="products-card" onClick={() => openModal(product)}>
            <MDBCardImage
              src={product.image}
              alt={product.title}
              position="top"
            />
            <MDBCardBody>
              <MDBCardTitle style={{ fontWeight: "bolder" }}>
                {product.title}
              </MDBCardTitle>
              {/* <MDBCardText>
                  Price: <strong>${product.price}</strong>
                </MDBCardText> */}
            </MDBCardBody>
          </MDBCard>
          //</MDBCol>
        ))}
      </div>
      {/* </MDBRow> */}

      {/* Render the Product Modal if a product is selected */}
      {selectedProduct && (
        <ProductModal
          product={selectedProduct}
          isOpen={modalOpen}
          closeModal={closeModal}
          handleIncreaseQuantity={handleIncreaseQuantity}
          handleDecreaseQuantity={handleDecreaseQuantity}
          addToCart={addToCart}
        />
      )}
    </div>
  );
}

export default Products;
