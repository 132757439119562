import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./../css/About.css";
import TeamMember from "../components/TeamMember";
const About = () => {
  const teamMembers = [
    {
      name: "Lucas Aponso",
      description:
        "Lucas Aponso is the Co-Founder and Executive Director of DataFarm, where he leads the company's vision and software development efforts to revolutionise agricultural data solutions. Lucas is focused on building effective embedded platforms that ensures farmers are confident in their purchase of our products.",
      image: "/assets/images/lucas_aponso.jpg", // Path to image
    },
    {
      name: "Mohnish Deshpande",
      description:
        "At DataFarm Mohnish plays a central role in shaping the company’s software strategy and product development. Relevant to DataFarm, Mohish has experience in IoT, embedded software and Cloud. With experience as an optimisation engineer in the software industry and an ex-competitive programmer, Mohnish focuses on efficiency. ",
      image: "/assets/images/mohnish_deshpande.jpg", // Path to image
    },
    {
      name: "Lawind Ismail",
      description:
        "Lawind Ismail is the Technical Director and Hardware Engineer at DataFarm, responsible for designing and optimising the hardware systems that support the company’s agricultural data solutions. With a strong background in hardware engineering, Lawind ensures that DataFarm’s sensor networks, IoT devices, and other technology platforms function seamlessly in the field. ",
      image: "/assets/images/lawind_ismail.jpg", // Path to image
    },
    {
      name: "Kalana Kannangara",
      description:
        "Kalana Kannangara is a Software Engineer and Technical Director at DataFarm, responsible for the design and implementation of the company's software systems, with a focus on making data accessible and actionable for farmers. Kalana works closely with the team to ensure the platform delivers real-time insights on weather patterns, soil health, irrigation, and crop performance. ",
      image: "/assets/images/kalana_kannangara.jpg", // Path to image
    },
  ];
  
  return (
    <Container className="about-container">
      <Row>
        <Col xs={12}>
          <div className="mission-statement">
            <h1 style={{ fontWeight: "bold", color: "Black" }}>
              Mission Statement
            </h1>
            <p>
            DataFarm’s mission is to equip farmers with the tools and insights needed to make informed, data-driven decisions that boost soil health, increase crop yields, and minimize environmental impact. Our vision is to become a global leader in agricultural technology, delivering sustainable solutions that support farmers, enrich communities, and protect the planet.
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <h1 style={{ fontWeight: "bold", color: "Black" }}>Our Team</h1>
        </Col>
        {teamMembers.map((member, index) => (
  <TeamMember
    key={member.name}
    name={member.name}
    description={member.description}
    image={member.image} // Pass the image prop
    isImageLeft={index % 2 === 0} // Alternates layout for each component
  />
))}
      </Row>
    </Container>
  );
};

export default About;
