import "../css/FAQ.css";
import { useEffect, useState, useRef } from "react";
import emptyImg from "../images/emptyImage.jpg";

//Array of questions and answers
export const questions = [
  {
    question: "Question 1",
    answer: "Answer 1",
  },
  {
    question: "Question 2",
    answer:
      "A Very very Very very Very very Very very Very very Very very Very very Very very Very very Very very Very very Very very Very very Very very Very very long answer",
  },
  {
    question: "Question 3",
    answer: "Answer 3",
  },
  {
    question: "Question 4",
    answer: "Answer 4",
  },
  {
    question: "Question 5",
    answer: "Answer 5",
  },
  {
    question: "Question 6",
    answer: "Answer 6",
  },
  {
    question: "Question 7",
    answer: "Answer 7",
  },
  {
    question:
      "A very veryvery veryvery veryvery veryvery veryvery veryvery very  veryvery very veryvery very veryvery very veryvery veryvery veryvery veryvery very long question",
    answer: "Answer 8",
  },
  {
    question: "Question 9",
    answer: "Answer 9",
  },
  {
    question: "Question 10",
    answer: "Answer 10",
  },
];

//Array of reviews
export const reviews = [
  {
    img: emptyImg,
    name: "FirstName LastName",
    date: "December 20, 2021",
    content:
      "Verry verry long amount of content Verry verry long amount of content Verry verry long amount of content Verry verry long amount of content ",
    rating: 5,
  },
  {
    img: emptyImg,
    name: "Name2",
    date: "December 15, 2021",
    content: "Content",
    rating: 1,
  },
  {
    img: emptyImg,
    name: "Name3",
    date: "December 10, 2021",
    content: "Content",
    rating: 3,
  },
  {
    img: emptyImg,
    name: "Name3",
    date: "December 10, 2021",
    content: "Content",
    rating: 3,
  },
];

//Stars componet, takes int from 1-5 as rating and number of stars
//uses the fortawesome icons
const Stars = ({ rating }) => {
  const stars = [];
  for (let i = 0; i < 5; i++) {
    stars.push(
      <i
        key={i}
        className={`fa-star ${i < rating ? "fas checked" : "far"}`}
      ></i>
    );
  }

  return <div className="stars">{stars}</div>;
};

//FAQ Card componet
export const FAQCard = ({ question, answer }) => {
  const [expanded, setExpanded] = useState(false);
  const bodyRef = useRef(null);

  //Handle transition animation when card is clicked
  useEffect(() => {
    if (expanded) {
      bodyRef.current.style.opacity = 1;
    } else {
      bodyRef.current.style.opacity = 0;
    }
  }, [expanded]);

  return (
    <div className="FAQCard">
      <div onClick={() => setExpanded(!expanded)} className="FAQCard-header">
        <h5 style={{ fontWeight: expanded ? "bold" : "normal" }}>{question}</h5>
        <button
          className="FAQCard-button"
          onClick={(e) => {
            e.stopPropagation();
            setExpanded(!expanded);
          }}
        >
          {expanded ? "−" : "+"}
        </button>
      </div>
      <div ref={bodyRef} className="FAQCard-body">
        {expanded && <p className="answer">{answer}</p>}
      </div>
    </div>
  );
};

//Reviews component
export const Reviews = ({ img, name, date, content, rating }) => {
  return (
    <div className="review">
      <div className="review-top">
        <div className="review-img">
          <img src={img} alt="photo of reviewer" />
        </div>
        <div className="review-name-date-and-rating">
          <div className="review-name-and-date">
            <div className="review-name">{name}</div>
            <div className="review-date">{date}</div>
          </div>
          <div className="review-rating">
            <Stars rating={rating} />
          </div>
        </div>
      </div>
      <div className="review-bottom">{content}</div>
    </div>
  );
};

//FAQ Page
const FAQ = () => {
  return (
    <div className="FAQ">
      <h1
        style={{
          paddingTop: "1rem",
          marginBottom: "2rem",
          fontWeight: "bolder",
        }}
      >
        Frequently Asked Questions
      </h1>
      <div className="FAQ-container">
        <div className="FAQ-container-left">
          {questions.slice(0, questions.length / 2).map((question) => (
            <FAQCard
              key={question.question}
              question={question.question}
              answer={question.answer}
            />
          ))}
        </div>
        <div className="FAQ-container-right">
          {questions.slice(questions.length / 2).map((question) => (
            <FAQCard
              key={question.question}
              question={question.question}
              answer={question.answer}
            />
          ))}
        </div>
      </div>
      <h1 style={{ fontWeight: "bolder", marginBottom: "2rem" }}> Reviews </h1>

      <div className="reviews-container">
        {reviews.map((review) => (
          <Reviews
            key={review.name}
            img={review.img}
            name={review.name}
            date={review.date}
            content={review.content}
            rating={review.rating}
          />
        ))}
      </div>
    </div>
  );
};

export default FAQ;
