import React from "react";
import "../css/ShoppingCart.css";
import { MDBIcon } from "mdb-react-ui-kit";

/**
 * Shopping cart page to display cart items and order summary
 * @param {Array} cartItems - Array of cart items
 * @param {function} handleIncreaseQuantity - Function to increase product quantity
 * @param {function} handleDecreaseQuantity - Function to decrease product quantity
 * @param {function} handleRemoveFromCart - Function to remove product from cart
 */
function ShoppingCart({
  cartItems,
  handleIncreaseQuantity,
  handleDecreaseQuantity,
  handleRemoveFromCart,
}) {
  const calculateTotal = () => {
    return cartItems
      .reduce((total, item) => total + item.price * item.quantity, 0)
      .toFixed(2);
  };

  return (
    <div className="cart-container">
      <div className="cart-items">
        <h1 style={{ marginBottom: "2rem", fontWeight: "bolder" }}>
          Your Cart
        </h1>
        {cartItems.length === 0 ? (
          <h5>Your cart is empty</h5>
        ) : (
          <div className="cart-list">
            {cartItems.map((item, index) => (
              <div className="cart-item" key={index}>
                <img src={item.imageUrl} alt="Product" />
                <div className="item-info">
                  <h5>
                    <strong>{item.productName}</strong>
                  </h5>
                  {/* Show single or total price based on screen size */}
                  <h6 className="price-single">Price: ${item.price}</h6>
                  <h6 className="price-total">
                    Total:{" "}
                    <strong>${(item.price * item.quantity).toFixed(2)}</strong>
                  </h6>
                </div>
                <div className="item-details">
                  <div className="item-actions">
                    <button onClick={() => handleDecreaseQuantity(index)}>
                      <MDBIcon fas icon="minus" />
                    </button>
                    <h6 style={{ marginBottom: "0" }}>{item.quantity}</h6>
                    <button onClick={() => handleIncreaseQuantity(index)}>
                      <MDBIcon fas icon="plus" />
                    </button>
                  </div>
                </div>
                {/* Show price in between quantity and remove button if screen is large */}
                <h6
                  className="item-price show-on-phone"
                  style={{ marginBottom: "0", fontWeight: "bold" }}
                >
                  ${(item.price * item.quantity).toFixed(2)}
                </h6>
                <div className="item-remove">
                  <button
                    onClick={() => handleRemoveFromCart(item.productName)}
                  >
                    <MDBIcon fas icon="times" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {/* Show order summary if cart is not empty */}
      {cartItems.length > 0 && (
        <div className="cart-summary">
          <h2>Order Summary</h2>
          <h5>
            Subtotal (
            {cartItems.reduce((total, item) => total + item.quantity, 0)}{" "}
            items): <strong>${calculateTotal()}</strong>
          </h5>
          <h5>Shipping: Free</h5>
          <button className="checkout-button">Checkout</button>
        </div>
      )}
    </div>
  );
}

export default ShoppingCart;
