import "../css/Contact.css";
import phoneIcon from "../images/Phone_Icon.png";
import emailIcon from "../images/Email_Icon.png";
import { FaInstagram, FaTwitter, FaLinkedin } from "react-icons/fa"; // Import social media icons

export const ContactCard = ({ imgSrc, contentHeading, content }) => {
  return (
    <div className="contact-card">
      <div className="contact-card-logo">
        <img src={imgSrc} alt="logo" className="contact-card-image" />
      </div>
      <div className="contact-card-info">
        <h3 className="contact-card-heading">{contentHeading}</h3>
        <p className="contact-card-content">{content}</p>
      </div>
    </div>
  );
};

const Contact = () => {
  return (
    <div className="contact">
      <div className="contact-header">
        <h1 className="contact-title">Contact Us</h1>
        <p className="contact-subtitle">We're here to help</p>
      </div>
      <div className="contact-card-container">
        <ContactCard
          content="0474 118 985"
          contentHeading="Call Us"
          imgSrc={phoneIcon}
        />
        <ContactCard
          content="datafarm@gmail.com"
          contentHeading="Email Us"
          imgSrc={emailIcon}
        />
      </div>
      <div className="contact-socials">
        <h3>Follow Us:</h3>
        <div className="social-icons">
          <a href="https://www.instagram.com/datafarm.tech/" target="_blank" rel="noopener noreferrer">
            <FaInstagram size={30} className="social-icon" />
          </a>
          <a href="https://www.linkedin.com/company/datafarm-tech/?viewAsMember=true" target="_blank" rel="noopener noreferrer">
            <FaLinkedin size={30} className="social-icon" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
