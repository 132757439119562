import Bluebutton from "../components/Bluebutton.js";
import "../css/sections/atop.css";
import React from "react";
import Carousel from 'react-bootstrap/Carousel';

const Atop = () => {
  return (
    <div className="landing-page">
      {/* Hero Section */}
      <section className="hero">
        <div className="hero-content">
          <h1>Welcome to DataFarm</h1>
          <p>Your Crop's Digital Companion.</p>
          {/* <Bluebutton label="Get Started" /> */}
        </div>
      </section>

      

      {/* Vision Section */}
      <section className="vision">
        <div className="vision-content">
          <div className="vision-text">
            <h2>Our Vision</h2>
            <p>
            DataFarm’s mission is to equip farmers with the tools and insights needed to make informed, data-driven decisions that boost soil health, increase crop yields, and minimize environmental impact. Our vision is to become a global leader in agricultural technology, delivering sustainable solutions that support farmers, enrich communities, and protect the planet.
            </p>
          </div>
          <div className="vision-image">
            <img src="/mission.jpg" alt="Vision" /> {/* Using public folder image */}
          </div>
        </div>
      </section>

      {/* Product Section */}
      <section className="product">
        <div className="product-content">
          <div className="product-image">
            <img src="/solution.jpg" alt="DataFarm Product" /> {/* Using public folder image */}
          </div>
          <div className="product-description">
            <h2>Our Solution</h2>
            <p>
            Innovative hardware and software system empowers farmers to make informed decisions that optimise resource use and maximise crop yields. Our system operates by deploying a network of sensor devices strategically placed throughout the field. Farmers can access this valuable information through our intuitive smartphone app. The app provides a user-friendly interface that displays real-time and historical soil data in clear, easy-to-understand visualisations

            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Atop;