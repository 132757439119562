import React, { useEffect, useState } from "react";
import "../css/ProductModal.css";
import { MDBIcon } from "mdb-react-ui-kit";
import "../css/ShoppingCart.css";

/**
 * Modal component to display product details
 * @param {object} product - Product object (exported from Products.js)
 * @param {boolean} isOpen - Modal open state (exported from Products.js)
 * @param {function} closeModal - Function to close the modal (exported from Products.js)
 * @param {function} handleIncreaseQuantity - Function to increase product quantity (exported from App.js through Products.js)
 * @param {function} handleDecreaseQuantity - Function to decrease product quantity (exported from App.js through Products.js)
 * @param {function} addToCart - Function to add product to cart (exported from App.js through Products.js)
 */
function ProductModal({
  product,
  isOpen,
  closeModal,
  handleIncreaseQuantity,
  handleDecreaseQuantity,
  addToCart,
}) {
  // Initialise quantity state
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    // Disable scrolling on the body element when modal is open
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // Clean up function to restore scrolling on unmount
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  // Return null if modal is closed or product is not provided
  if (!isOpen || !product) return null;

  // Handle function to add product to cart and close modal once added
  const handleAddToCart = (product) => {
    addToCart(product.title, quantity, product.price, product.image);
    closeModal();
  };

  // Increase quantity function
  const increaseQuantity = () => {
    setQuantity(quantity + 1);
  };

  // Decrease quantity function
  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleDownloadManual = () => {
    const link = document.createElement("a");
    // TODO: Replace the file path with the actual file path
    link.href = process.env.PUBLIC_URL + "documents/test.pdf"; // File path in public folder
    link.download = "manual.pdf"; // File name that the file will be downloaded as
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    closeModal(); // Close modal after download
  };

  // Close modal when clicking outside the modal content
  const handleClickOutside = (e) => {
    if (e.target.className === "product-modal") {
      closeModal();
    }
  };

  return (
    <div className="product-modal-wrapper" onClick={handleClickOutside}>
      <div className="product-modal">
        <div className="product-modal-content">
          <span className="close" onClick={closeModal}>
            &times;
          </span>
          <img src={product.image} alt={product.title} />
          <h2 style={{ fontWeight: "bolder" }}>{product.title}</h2>
          {/* <h6>
            Price: <strong>${product.price}</strong>
          </h6> */}
          <h6>Description: {product.description}</h6>
          <div className="item-details">
            <h6
              className="quantity-label"
              style={{ padding: "0.5rem 0.5rem 0 0" }}
            >
              Quantity:
            </h6>
            <div className="item-actions">
              <button onClick={decreaseQuantity}>
                <MDBIcon fas icon="minus" />
              </button>
              <h6 style={{ marginBottom: "0" }}>{quantity}</h6>
              <button onClick={increaseQuantity}>
                <MDBIcon fas icon="plus" />
              </button>
            </div>
          </div>
          <button
            className="add-to-cart-btn"
            onClick={() => handleAddToCart(product)}
          >
            Add to Cart
          </button>
          <button className="download-btn" onClick={handleDownloadManual}>
            Download Manual
          </button>
        </div>
      </div>
    </div>
  );
}

export default ProductModal;
