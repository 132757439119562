import React from "react";
import { Row, Col } from "react-bootstrap";
import "./../css/About.css"; // Your custom styles

const TeamMember = ({ name, description, image, isImageLeft }) => {
  return (
    <Row
      className="team-member"
      style={{
        padding: 10,
        flexDirection: isImageLeft ? "row" : "row-reverse",
        marginBottom: 30,
      }}
    >
      {/* Image section */}
      <Col xs={12} md={4} lg={3} xxl={2}>
        <div className="avatar">
          <img
            src={image} // Use the image path from the props
            alt={name} // Alt text for accessibility
            style={{
              width: "100%",
              height: "auto",
              borderRadius: "50%", // Optional: to make the image circular
              objectFit: "cover", // Optional: to crop the image to fit
            }}
          />
        </div>
      </Col>
      
      {/* Name and Description */}
      <Col xs={12} md={8} lg={9} xxl={10} style={{ padding: 0, margin: 0 }}>
        <Row className="name" style={{ margin: 0 }}>
          <h3 style={{ margin: 0 }}>{name}</h3>
        </Row>
        <Row className="desc" style={{ marginTop: 40, marginLeft: 0 }}>
          <p>{description}</p>
        </Row>
      </Col>
    </Row>
  );
};

export default TeamMember;
