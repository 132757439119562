import React from 'react';
import '../css/Bluebutton.css';

class Bluebutton extends React.Component {
    scrollToHeader = () => {
      const header = document.getElementById(this.props.targetHeaderId);
      if (header) {
        header.scrollIntoView({ behavior: 'smooth' });
      }
    };
  
    render() {
      return (
        <button className="bluebutton" onClick={this.scrollToHeader}>
          Learn more {this.props.children}
        </button>
      );
    }
}
  

export default Bluebutton;