import React from "react";
import "../css/Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="socials">
        <a
          href="https://www.instagram.com/datafarm.tech/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-instagram"></i>
        </a>
        <a
          href="https://www.linkedin.com/company/datafarm-tech/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-linkedin-in"></i>
        </a>
        <a
          href="mailto:datafarm.tech@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fas fa-envelope"></i>
        </a>
      </div>
      <div className="rights">
        <p>&copy; 2024 DataFarm. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
