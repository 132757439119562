// src/App.js
import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import NoPage from "./pages/NoPage";
import ShoppingCart from "./pages/ShoppingCart";
import FAQ from "./pages/FAQ";
import Products from "./pages/Products";

function App() {
  const [cartItems, setCartItems] = useState([]);

  // Load cart items from local storage on initial render
  useEffect(() => {
    const cart = JSON.parse(localStorage.getItem("cart")) || [];
    setCartItems(cart);
  }, []);

  // Add product to cart
  const addToCart = (productName, quantity, price, imageUrl) => {
    const cart = JSON.parse(localStorage.getItem("cart")) || [];
    const existingItemIndex = cart.findIndex(
      (item) => item.productName === productName
    );
    if (existingItemIndex > -1) {
      cart[existingItemIndex].quantity += quantity;
    } else {
      cart.push({ productName, quantity, price, imageUrl });
    }
    setCartItems(cart);
    localStorage.setItem("cart", JSON.stringify(cart));
    window.dispatchEvent(new Event("storage"));
  };

  // Increase quantity of product in cart
  const handleIncreaseQuantity = (index) => {
    const cart = [...cartItems];
    cart[index].quantity += 1;
    setCartItems(cart);
    localStorage.setItem("cart", JSON.stringify(cart));
    window.dispatchEvent(new Event("storage"));
  };

  // Decrease quantity of product in cart
const handleDecreaseQuantity = (index) => {
  const cart = [...cartItems];
  if (cart[index].quantity > 1) {
    cart[index].quantity -= 1;
    // Update cart items and local storage
    setCartItems(cart);
    localStorage.setItem("cart", JSON.stringify(cart));
  } else {
    // Remove product from cart if quantity is 1
    handleRemoveFromCart(cart[index].productName);
  }
  window.dispatchEvent(new Event("storage"));
};

// Remove product from cart
const handleRemoveFromCart = (productName) => {
  const cart = cartItems.filter((item) => item.productName !== productName);
  setCartItems(cart);
  localStorage.setItem("cart", JSON.stringify(cart));
  window.dispatchEvent(new Event("storage"));
};

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />{" "}
          <Route path="*" element={<NoPage />} />{" "}
          <Route
            path="shopping-cart"
            element={
              <ShoppingCart
                cartItems={cartItems}
                handleDecreaseQuantity={handleDecreaseQuantity}
                handleIncreaseQuantity={handleIncreaseQuantity}
                handleRemoveFromCart={handleRemoveFromCart}
              />
            }
          />
          <Route path="FAQ" element={<FAQ />} />
          <Route
            path="products"
            element={
              <Products
                handleDecreaseQuantity={handleDecreaseQuantity}
                handleIncreaseQuantity={handleIncreaseQuantity}
                addToCart={addToCart}
              />
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
