import { Outlet } from "react-router-dom";
import Navbar from "../components/Navbar.js";
import "../css/Layout.css";
import Footer from "../components/Footer.js";

const Layout = () => {
  return (
    <div className="app-container">
      <Navbar />
      <div className="mainbody">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
