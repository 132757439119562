import React, { useState, useEffect } from "react";
import "../css/Navbar.css"; // Import your CSS file for styling
import logo from "../images/DataFarm.jpg";
import { NavLink } from "react-router-dom"; 
import shoppingCartIcon from "../images/ShoppingCartIcon.png";
import hamburger from "../images/hamburger.png";
import contributeIcon from "../images/ContributeIcon.png";
import closeIcon from "../images/CloseIcon.png";

//map of nav bar elements with links
const navElements = new Map([
  ["Home", "/"],
  // ["Products", "/products"],
  //["FAQ", "/faq"],
  ["About Us", "/about"],
  ["Contact Us", "/contact"],
]);

//map of special nav elements with links
const SpecialNavElements = new Map([
  ["Contribute", "/contribute"],
  //["Shopping Cart", "/shopping-cart"],
]);

//Only displays when screen is small enough
const HamburgerMenu = ({ toggleSidebar }) => {
  return (
    <div className="nav-hamburger-menu" onClick={toggleSidebar}>
      <div className="nav-hamburger-sidebar"></div>
      <img className="nav-hamburger" alt="" src={hamburger} />{" "}
    </div>
  );
};

// Function to handle clicking on Contribute button
const handleContributeClick = () => {
  window.location.href = "https://buymeacoffee.com/datafarm";
};

const SideBar = ({ toggleSidebar, showSidebar }) => {
  return (
    <div className={`sidebar ${showSidebar ? "sidebar-visible" : ""}`}>
      <div className="sidebar-header">
        <h1>Menu </h1>
        <button className="sidebar-close-button" onClick={toggleSidebar}>
          <img className="sidebar-closeIcon" alt="" src={closeIcon} />
        </button>
      </div>
      <div className="sidebar-elements">
        {Array.from(navElements, ([key, value]) => (
          <NavLink className="sidebar-item" to={value} activeClassName="active">
            {key}
          </NavLink>
        ))}
      </div>
      <div className="sidebar-special-elements">
        {/* <NavLink className="sidebar-shopping-cart" to="/shopping-cart">
          <img
            className="sidebar-shoppingCartIcon"
            alt="Shopping Cart"
            src={shoppingCartIcon}
            width="50px"
          ></img>
          Shopping Cart
        </NavLink> */}
        <NavLink
          className="sidebar-shopping-cart"
          style={{ backgroundColor: "#FFD700" }}
          onClick={handleContributeClick}
        >
          <img
            className="sidebar-shoppingCartIcon"
            alt="Contribute"
            src={contributeIcon}
            width="50px"
          ></img>
          Contribute
        </NavLink>
      </div>
    </div>
  );
};

const Navbar = () => {
  // Cart number notification
  const [cartCount, setCartCount] = useState(0);

  // Update cart count when localStorage changes
  useEffect(() => {
    const updateCartCount = () => {
      const cartItems = JSON.parse(localStorage.getItem("cart")) || [];
      const totalCount = cartItems.reduce(
        (total, item) => total + item.quantity,
        0
      );
      setCartCount(totalCount);
    };

    // Initial update of cart count
    updateCartCount();

    // Subscribe to storage events to update cart count dynamically
    window.addEventListener("storage", updateCartCount);

    // Cleanup: Remove event listener when component unmounts
    return () => {
      window.removeEventListener("storage", updateCartCount);
    };
  }, []);

  const [showSidebar, setShowSidebar] = React.useState(false);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  return (
    <div className="navbar-and-sidebar">
      <SideBar toggleSidebar={toggleSidebar} showSidebar={showSidebar} />

      <header id="navbar">
        <div className="headerlogo">
          <NavLink to="/">
            <img className="headerlogoimage" alt="" src={logo} />
          </NavLink>
        </div>
        <nav className="nav-elements">
          {Array.from(navElements, ([key, value]) => (
            <NavLink className="nav-item" to={value} activeClassName="active">
              {key}
            </NavLink>
          ))}
        </nav>
        <nav className="special-nav-elements">
          {/* Replace Link with <a> tag for the Contribute button */}
          <a
            className="contribute-button"
            href="https://buymeacoffee.com/datafarm"
            onClick={handleContributeClick}
          >
            Contribute
          </a>

          {/* <Link className="shopping-cart-button" to="/shopping-cart">
            <img
              className="shoppingCartIcon"
              alt="Shopping Cart"
              src={shoppingCartIcon}
              width="50px"
            />
            {cartCount > 0 && <span className="cart-badge">{cartCount}</span>}
          </Link> */}
        </nav>
        <HamburgerMenu toggleSidebar={toggleSidebar} />{" "}
        {/* Only displays when screen is small enough */}
      </header>
    </div>
  );
};

export default Navbar;
